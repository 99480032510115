<template>
  <div>
    <div class="row text-center">
      <div class="col-xs-12 col-md-4 col-lg-4">
        <div class="overview-box overview-box-3">
          <div class="overview-box-title">
            <i class="pi pi-fw pi-sitemap"></i><span>Tagesumsatz</span>
          </div>

          <br />
          <Calendar v-model="day" dateFormat="dd-mm-yy"  />
        </div>
      </div>
      <div class="col-xs-12 col-md-4 col-lg-4">
        <div class="overview-box overview-box-2">
          <div class="overview-box-title">
            <i class="pi pi-map-marker"></i><span>Monatsumsatz</span>
          </div>

          <br />
          <Calendar v-model="month" view="month" dateFormat="mm/yy" />
        </div>
      </div>
      <div class="col-xs-12 col-md-4 col-lg-4">
        <div class="overview-box overview-box-1">
          <div class="overview-box-title">
            <i class="pi pi-fw pi-user"></i><span>Jahresumsatz</span>
          </div>
          <br />
          <Calendar2 v-model="year" view="year" dateFormat="yy" />
        </div>
      </div>
    </div>

    <br />
    <br />
    <!-- <DataTable
      ref="dt"
      :value="list"
      class="p-datatable-customers"
      showGridlines
    >
      <template #header>
        <div class="table-header">
        
          </div>
        </div>
      </template>
      <template #empty>Keine Daten</template>
      <template #loading>Daten werden geladen, bitte warten... </template>

      <ColumnGroup type="header">
        <Row>
          <Column :rowspan="2" header="Bezeichnung" />
          <Column :rowspan="2" header="Preise" />

          <Column :rowspan="2" header="Hotel Gaste" />

          <Column :rowspan="2" header="Bar" />

          <Column :rowspan="2" header="Kartenzahlung" />
          <Column :rowspan="2" header="Gutschein" />
          <Column :rowspan="2" header="10er Block" />
          <Column :rowspan="2" header="online" />
          <Column :rowspan="2" header="Produkte" />

        </Row>
        <Row>
         
        </Row>
      </ColumnGroup>

      <Column filterMatchMode="contains" field="name">
        <template #body="slotProps">
          {{ slotProps.data.start }}
        </template>
      </Column>
      <Column filterMatchMode="contains" field="Preise">
        <template #body="slotProps">
          {{
            slotProps.data.list
              .map((el) => el.Preise)
              .reduce((a, b) => Number(a) + Number(b), 0)
          }}
        </template>
      </Column>

      <Column filterMatchMode="contains" field="HotelPreise">
        <template #body="slotProps">
          {{
            slotProps.data.list
              .map((el) => el.HotelPreise)
              .reduce((a, b) => Number(a) + Number(b), 0)
          }}
        </template>
      </Column>

     

      <Column filterMatchMode="contains" field="Bar">
        <template #body="slotProps">
          {{
            slotProps.data.list
              .map((el) => el.Bar)
              .reduce((a, b) => Number(a) + Number(b), 0)
          }}
        </template>
      </Column>
      <Column filterMatchMode="contains" field="Kartenzahlung">
        <template #body="slotProps">
          {{
            slotProps.data.list
              .map((el) => el.Kartenzahlung)
              .reduce((a, b) => Number(a) + Number(b), 0)
          }}
        </template>
      </Column>
      <Column filterMatchMode="contains" field="GutsheinBetrag">
        <template #body="slotProps">
          {{
            slotProps.data.list
              .map((el) => el.GutsheinBetrag)
              .reduce((a, b) => Number(a) + Number(b), 0)
          }}
        </template>
      </Column>
      <Column filterMatchMode="contains" field="BlockBetrag">
        <template #body="slotProps">
          {{
            slotProps.data.list
              .map((el) => el.BlockBetrag)
              .reduce((a, b) => Number(a) + Number(b), 0)
          }}
        </template>
      </Column>
      <Column filterMatchMode="contains" field="online">
        <template #body="slotProps">
          {{
            slotProps.data.list
              .map((el) => el.online)
              .reduce((a, b) => Number(a) + Number(b), 0)
          }}
        </template>
      </Column>
      <Column filterMatchMode="contains" field="Produkte">
        <template #body="slotProps">
          {{
            slotProps.data.list
              .map((el) => el.Produkte)
              .reduce((a, b) => Number(a) + Number(b), 0)
          }}
        </template>
      </Column>


      <ColumnGroup type="footer">
        <Row>
          <Column footer=" " :footerStyle="{ 'text-align': 'center' }" />
          <Column
            :footer="
              oldList
                .map((el) => el.Preise)
                .reduce((a, b) => Number(a) + Number(b), 0)
            "
            :footerStyle="{ 'text-align': 'center' }"
          />

          <Column
            :footer="
              oldList
                .map((el) => el.HotelPreise)
                .reduce((a, b) => Number(a) + Number(b), 0)
            "
            :footerStyle="{ 'text-align': 'center' }"
          />


          <Column
            :footer="
              oldList
                .map((el) => el.Bar)
                .reduce((a, b) => Number(a) + Number(b), 0)
            "
            :footerStyle="{ 'text-align': 'center' }"
          />
          <Column
            :footer="
              oldList
                .map((el) => el.Kartenzahlung)
                .reduce((a, b) => Number(a) + Number(b), 0)
            "
            :footerStyle="{ 'text-align': 'center' }"
          />
          <Column
            :footer="
              oldList
                .map((el) => el.GutsheinBetrag)
                .reduce((a, b) => Number(a) + Number(b), 0)
            "
            :footerStyle="{ 'text-align': 'center' }"
          />

          <Column
            :footer="
              oldList
                .map((el) => el.BlockBetrag)
                .reduce((a, b) => Number(a) + Number(b), 0)
            "
            :footerStyle="{ 'text-align': 'center' }"
          />

          <Column
            :footer="
              oldList
                .map((el) => el.online)
                .reduce((a, b) => Number(a) + Number(b), 0)
            "
            :footerStyle="{ 'text-align': 'center' }"
          />

          <Column
            :footer="
              oldList
                .map((el) => el.Produkte)
                .reduce((a, b) => Number(a) + Number(b), 0)
            "
            :footerStyle="{ 'text-align': 'center' }"
          />
        </Row>
      </ColumnGroup>
    </DataTable> -->
  </div>
</template>

<script>
import moment from "moment";

export default {
  data() {
    return {
      list: [],
      oldList: [],
      day: null,
      month: null,
      year: null,
    };
  },
  methods: {
    // getData() {
    //   this.$http.get(`appointments?isR=0`).then(
    //     (response) => {
    //       this.loading = false;

    //       this.oldList = response.data.data;

    //       const list = [];

    //       for (const item of response.data.data) {
    //         const index = list.findIndex((el) =>
    //           item.start.startsWith(el.start)
    //         );
    //         if (index >= 0) {
    //           list[index].list.push(item);
    //         } else {
    //           list.push({
    //             start: item.start.slice(0, 4),
    //             list: [item],
    //           });
    //         }
    //       }
    //       this.list = list;
    //     },
    //     (err) => {
    //       this.loading = false;
    //       this.$toast.add({
    //         severity: "error",
    //         summary: "Error",
    //         detail: err.response.data.message,
    //         life: 3000,
    //       });
    //     }
    //   );
    // },
  },
  created() {
    this.getData();
  },
  watch: {
    year(value) {
      if (value) {
        const duration = moment(String(value));

        const from = duration.format("YYYY");
        this.$router.push("byyear/" + from);
      }
    },
    day(val) {
      if (val) {
        this.$router.push("byday/" + this.$durationFormat2(val));
      }
    },
    month(value) {
      if (value) {
        const duration = moment(String(value));

        const from = duration.format("YYYY-MM");
        this.$router.push("bymonth/" + from);
      }
    },
  },
};
</script>

<style>
.overview-box {
  padding: 15px;
  color: #ffffff;
  min-height: 100px;
  border-radius: 4px;
  margin: 0 !important;
}
.overview-box .overview-box-title {
  font-weight: bold;
  width: 100%;
}
.overview-box .overview-box-title i {
  vertical-align: middle;
  font-size: 20px;
}
.overview-box .overview-box-title span {
  margin-right: 0.5em;
  vertical-align: middle;
}
.overview-box .overview-box-count {
  color: #ffffff;
  font-size: 24px;
  width: 100%;
  display: block;
  padding: 5px 0;
}
.overview-box.overview-box-1 {
  background-color: #007bff;
  border: solid 1px #007bff;
  color: #ffffff;
}
.overview-box.overview-box-2 {
  background-color: #28a745;
  border: solid 1px #28a745;
  color: #ffffff;
}
.overview-box.overview-box-3 {
  background-color: #fd7e14;
  border: solid 1px #fd7e14;
  color: #ffffff;
}
.overview-box.overview-box-4 {
  background-color: #6f42c1;
  border: solid 1px #6f42c1;
  color: #ffffff;
}
</style>
